import { BrowserRouter, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Dashboard from "./page/dashboard";
import GamePage from "./page/game";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";

function App() {
  return (
    <>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route path="/game/:id" component={GamePage} />
        </Switch>
      </BrowserRouter>
      <ToastContainer theme="dark" autoClose="3000" />
    </>
  );
}

export default App;
