import { useEffect, useState } from "react";
import AboutUs from "../component/aboutus";
import Carousel from "../component/carousel/carousel";
import ContactUs from "../component/contactus";
import Hero from "../component/hero";
import Navbar from "../component/navbar";
import Footer from "../component/footer";

export default function Dashboard() {
  const [data, setData] = useState();

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/data/game.json`)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
      });
  }, []);
  return (
    <>
      <div className="min-h-full bg-b">
        <Navbar />
        <main className="container mx-auto">
          <section className="rounded-box relative scroll-m-20" id="home">
            <div className="hero-bg absolute box-shadow top-0 left-0 right-0 rounded-box h-[90%]"></div>
            <Hero />
          </section>

          <section
            className="bg-even rounded-box mb-10  px-4 scroll-m-20 box-shadow"
            id="aboutus"
          >
            <h2 className="text-3xl text-pantone  text-center py-8">
              ABOUT <span className=" font-bold"> US</span>
            </h2>
            <AboutUs />
          </section>
          <section
            className="bg-odd rounded-box my-10 pb-4 px-4 scroll-m-20 box-shadow"
            id="ourgames"
          >
            <h2 className="text-3xl text-pantone text-center pt-8">Games</h2>
            <div>
              <Carousel data={data} />
            </div>
          </section>
          <section
            className="bg-even rounded-box my-8 scroll-m-20 box-shadow"
            id="contactus"
          >
            <ContactUs />
          </section>
        </main>
        <Footer />
      </div>
    </>
  );
}
