import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const _navigation = [
  { name: "Home", href: "/#home", current: true },
  { name: "About Us", href: "/#aboutus", current: false },
  { name: "Games", href: "/#ourgames", current: false },
  { name: "Contact Us", href: "/#contactus", current: false },
];

export default function Navbar() {
  const [navigate, useNavigate] = useState(_navigation);

  const location = useLocation();

  useLayoutEffect(() => {
    const hash = location.hash;
    if (!hash) {
      return;
    }
    // eslint-disable-next-line
    useNavigate((prev) => {
      return prev.map((item) => {
        item.current = item.href === hash;
        return item;
      });
    });
  }, [location]);

  return (
    <Disclosure
      as="nav"
      className="sticky top-0 w-full navbar-container text-base z-20"
    >
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 items-center justify-between">
          <div className="flex items-center">
            <a className="flex-shrink-0" href="#home">
              <img
                alt="Playzumi"
                src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
                className="w-48"
              />
            </a>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                {navigate.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    aria-current={item.current ? "page" : undefined}
                    className={classNames(
                      item.current
                        ? "bg-gray-900 text-[#c9f1f9]"
                        : "text-[#c9f1f9] hover:bg-gray-700 hover:text-[#c9f1f9]",
                      "rounded-md px-3 py-2"
                    )}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </div>

          <div className="-mr-2 flex md:hidden">
            {/* Mobile menu button */}
            <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
              <span className="absolute -inset-0.5" />
              <span className="sr-only">Open main menu</span>
              <Bars3Icon
                aria-hidden="true"
                className="block h-6 w-6 group-data-[open]:hidden"
              />
              <XMarkIcon
                aria-hidden="true"
                className="hidden h-6 w-6 group-data-[open]:block"
              />
            </DisclosureButton>
          </div>
        </div>
      </div>

      <DisclosurePanel className="md:hidden">
        <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
          {navigate.map((item) => (
            <DisclosureButton
              key={item.name}
              as="a"
              href={item.href}
              aria-current={item.current ? "page" : undefined}
              className={classNames(
                item.current
                  ? "bg-gray-900 text-white"
                  : "text-gray-300 hover:bg-gray-700 hover:text-white",
                "block rounded-md px-3 py-2 text-base "
              )}
            >
              {item.name}
            </DisclosureButton>
          ))}
        </div>
      </DisclosurePanel>
    </Disclosure>
  );
}
