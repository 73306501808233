export default function Hero() {
  return (
    <div className="hero relative rounded-box">
      <div className="hero-content text-center">
        <div>
          <div className="max-w-md mx-auto pt-4">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
              alt="logo"
            />
            <p className="pb-4 tracking-wider text-2xl text-slate-200">
              Delivering Premium Quality Slots
            </p>
          </div>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/hero_banner.png`}
            alt="hero banner"
            height={600}
            width={1248}
          />
        </div>
      </div>
    </div>
  );
}
