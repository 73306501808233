export default function Footer() {
  return (
    <footer className="footer">
      <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
        <span className="flex items-center justify-center gap-2 text-sm text-gray-300 sm:text-center dark:text-gray-600">
          © 2024{" "}
          <a href="#home">
            <img
              alt="Playzumi"
              src="/assets/images/logo.png"
              className="w-24"
            />
          </a>
          All Rights Reserved.
        </span>
        <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-300 dark:text-gray-600 sm:mt-0">
          {/* <li>
            <a href="#" className="hover:underline me-4 md:me-6">
              Privacy Policy
            </a>
          </li>
          <li>
            <a href="#" className="hover:underline me-4 md:me-6">
              Licensing
            </a>
          </li> */}
        </ul>
      </div>
    </footer>
  );
}
