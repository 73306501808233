export const defaultSlidePerBreakpoints = {
    320: {
        slidesPerView: 1,
    },
    480: {
        slidesPerView: 2,
    },
    640: {
        slidesPerView: 3,
    },
    768: {
        slidesPerView: 3,
    },
    1024: {
        slidesPerView: 3,
    },
    1280: {
        slidesPerView: 4,
    },
    1536: {
        slidesPerView: 4,
    },
};

export const GameBigSlidePerBreakpoints = {
    320: {
        slidesPerView: 1,
    },
    480: {
        slidesPerView: 1,
    },
    640: {
        slidesPerView: 1,
    },
    768: {
        slidesPerView: 1,
    },
    1024: {
        slidesPerView: 1,
    },
    1280: {
        slidesPerView: 1,
    },
    1536: {
        slidesPerView: 1,
    },
};